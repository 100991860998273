.snackbar {
	visibility: hidden;
	min-width: 250px;
	position: fixed;
	z-index: 101;
	left: 50%;
	bottom: -6%;
	max-width: 360px;
	transition: transform 0.8s;
	transform: translateX(-50%);
}

.snackbar.show {
	visibility: visible;
	transform: translate(-50%, -250%);
}

.snackbar.fadeOut {
	visibility: visible;
	transform: translate(-50%, 250%);
}

.max-width-75-percentage {
	max-width: 75%;
}
