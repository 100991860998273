.height-64px {
  height: 64px;
}

.font-size-18px {
  font-size: 18px;
}

.outline-none {
  outline: none;
}

.cursor-edit-color {
  caret-color: white;
}

.border-bottom-tertiary-dark {
  border-bottom: 1px solid #c2c2c2;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.glass-effect {
  background: rgba(255, 255, 255, 0.2);
}

.text-underline {
  text-decoration: underline;
  text-underline-offset: 4px;
}

.padding-left-36px {
  padding-left: 36px;
}

.padding-right-36px {
  padding-right: 36px;
}

.background-color-black-with-opacity-dark {
  background: rgba(0, 0, 0, 0.5);
}

.width-95-percentage {
  width: 95%;
}

.flex-1-0 {
  flex: 1 0;
}

.inherit-parent-width-subtract-70px {
  width: calc(100% - 70px);
}