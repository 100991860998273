.hide-scroll-bar {
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.hide-scroll-bar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.height-width-30px {
  height: 30px;
  width: 30px;
}
.top-50-percentage {
  top: 50%;
}
.transform-translateY--50-percentage {
  transform: translateY(-50%);
}

.left-3px {
  left: 3px;
}

.transform-rotate-180-deg {
  transform: rotate(180deg);
}

.right-3px {
  right: 3px;
}

.edit-document-options > button {
  --btn-size: 40px;
  border-radius: 50%;
  height: var(--btn-size);
  width: var(--btn-size);
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 5px rgba(0, 0, 0, 0.12),
    0px 2px 2px rgba(0, 0, 0, 0.15);
}

.edit-btn-with-options {
  background-color: #f1f1f1;
  position: relative;
  height: var(--btn-size);
  width: var(--btn-size);
}

/* color & Brush selection btn root */
.edit-btn-with-options > span,
.edit-btn-with-options > img {
  position: absolute;
  display: block;
  background-color: #ffff;
  border: 12px solid #ffff;
  width: calc(var(--btn-size));
  height: calc(var(--btn-size));
  border-radius: 50%;
}

.edit-options-wrapper {
  position: absolute;
  bottom: 0;
  background-color: #f1f1f1;
  width: 100%;
  border-radius: 500px;
  padding-bottom: var(--btn-size);
}

/* color & Brush options */
.edit-options-wrapper > span,
.edit-options-wrapper > img {
  display: block;
  border: 12px solid #f1f1f1;
  width: calc(var(--btn-size));
  height: calc(var(--btn-size));
  border-radius: 50%;
}

.height-80vh {
  height: 80vh;
}

.height-60vh {
  height: 60vh;
}

.background-color-black {
  background-color: black;
}

.background-color-white {
  background-color: #ffff;
}

.background-color-dark-grey {
  background-color: var(--secondaryColor);
}

.background-color-primary-green {
  background-color: var(--primaryColor);
}

.background-color-red {
  background-color: #e64539;
}

.justify-content-start {
  justify-content: start;
}

.background-color-transparent-black-lite {
  background-color: rgba(0, 0, 0, 0.418);
}
.background-color-white {
  background-color: #ffff;
}
.height-75-percentage {
  height: 75%;
}

/* --- media queries --- */
@media screen and (min-width: 576px) {
  .edit-document-options > button {
    --btn-size: 50px;
  }
}
