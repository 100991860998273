.width-16px {
  min-width: 10px;
}

.height-32px {
  height: 32px;
}

.black-dashed-border {
  background-image: linear-gradient(#404040 55%, rgba(255, 255, 255, 0) 0%);
  background-position: right;
  background-size: 5px 14px;
  background-repeat: repeat-y;
}

.white-dashed-border {
  background-image: linear-gradient(#ffffff 55%, rgba(255, 255, 255, 0) 0%);
  opacity: 0.5;
  background-position: right;
  background-size: 5px 14px;
  background-repeat: repeat-y;
}

.grey-dashed-border {
  background-image: linear-gradient(#404040 55%, rgba(255, 255, 255, 0) 0%);
  opacity: 0.5;
  background-position: right;
  background-size: 5px 14px;
  background-repeat: repeat-y;
}

.background-404040 {
  background: #404040;
}