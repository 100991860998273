.carousel .control-arrow {
  opacity: 0.7 !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid black !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid black !important;
}
