.border-radius-top-right-default {
  border-radius: 0px 8px 0 0;
}

.border-bottom-tertiary-dark {
  border-bottom: 1px solid var(--tertiaryDarkColor);
}

.border-left-tertiary-dark {
  border-left: 1px solid var(--tertiaryDarkColor);
}

.background-color-black {
  background-color: #000;
}

.margin-top-16px {
  margin-top: 16px;
}
