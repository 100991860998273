.padding-32px {
  padding: 32px;
}

.border-left-2px-quaternary {
  border-left: 2px solid #c2c2c2;
}

.border-bottom-2px-quaternary {
  border-bottom: 2px solid #c2c2c2;
}

.border-bottom-2px-secondary {
  border-bottom: 2px solid #404040;
}

.border-bottom-1px-secondary {
  border-bottom: 1px solid #404040;
}

.border-bottom-2px-white {
  border-bottom: 2px solid white;
}

.font-size-32px {
  font-size: 24px;
}

.width-400px {
  width: 400px;
}

.background-selected-card-gradient-e5e5e5-dark-left-to-right {
  background: linear-gradient(
    90deg,
    rgba(229, 229, 229, 0.4),
    rgba(229, 229, 229, 0)
  );
}

.background-selected-card-gradient-e5e5e5-light-left-to-right {
  background: linear-gradient(
    90deg,
    rgba(229, 229, 229, 1),
    rgba(229, 229, 229, 0)
  );
}

.background-selected-card-gradient-e5e5e5-dark-right-to-left {
  background: linear-gradient(
    270deg,
    rgba(229, 229, 229, 0.4),
    rgba(229, 229, 229, 0)
  );
}

.background-selected-card-gradient-e5e5e5-light-right-to-left {
  background: linear-gradient(
    270deg,
    rgba(229, 229, 229, 1),
    rgba(229, 229, 229, 0)
  );
}

#input-box-selection input[type="text"] {
  padding: 8px;
}
